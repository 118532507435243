@import '../../../../scss/theme-bootstrap';

// Header interaction
.gnav-util--cart {
  @include breakpoint($portrait-up) {
    @if $cr20 == true {
      font-size: 17px;
    }
  }
  .icon--close,
  .icon--bag {
    @include breakpoint($portrait-up) {
      @if $cr20 == true {
        margin-top: -2px;
      }
    }
  }
  .icon--bag {
    width: 20px;
    height: 20px;
    @include breakpoint($portrait-up) {
      @if $cr20 == true {
        width: 17px;
        height: 17px;
      }
    }
    @include breakpoint($landscape-up) {
      width: 24px;
      height: 24px;
      @if $cr20 == true {
        width: 17px;
        height: 17px;
      }
    }
  }
  .icon--close {
    display: none;
  }
  // By default, display none
  .gnav-util__content--cart-carbon-offset,
  .gnav-util__content--cart {
    display: none;
    border: $color-off-black solid 1px;
    position: absolute;
    #{$rdirection}: 0;
    background: $white;
    z-index: 2;
    overflow-y: auto;
    padding-top: 25px;
    @include breakpoint($portrait-up) {
      margin-top: 7px;
    }
  }
  // When this is active show the X Icon
  // Also show the body of the cart
  &.active {
    .icon--close {
      display: inline-block;
      width: 17px;
      height: 17px;
      position: absolute;
      z-index: 3;
      top: 50px;
      #{$rdirection}: 9px;
    }
    .gnav-util__content--cart {
      display: block;
      overflow: hidden;
    }
    .gnav-util__content--cart-carbon-offset {
      display: block;
    }
  }
  .gnav-util__content--cart-carbon-offset {
    .cloverly-icon {
      float: $ldirection;
      margin: 8px;
    }
    &--info {
      font-size: 14px;
      font-weight: bold;
      margin-#{$ldirection}: 16%;
    }
    .cart-block {
      &__footer {
        padding: 10px 10px 20px;
        &__summary {
          border-bottom: $border;
          padding-bottom: 12px;
        }
      }
    }
  }
}
